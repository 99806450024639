import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de color blau i marges blanquinosos (quan és jove) que es desenvolupa damunt la fusta en forma de crosta amb una superfície llisa o irregular formant una successió de nusos.
Les espores són el·líptiques, hialines, de 7-11 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      